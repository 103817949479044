::selection {
background-color: 35;
color: 36;
}
::-moz-selection {
background-color: 35;
color: 36;
}
body { background-color: var(--clr-158383); }
body { color: #000000; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-158384);
font-family: 'Source Sans Pro';
font-weight: 300;
font-style: normal;
line-height: 1;
font-size: 60px;

@media #{$large-up} {
font-size: 100px;

}
}
h2 {
color: var(--clr-158384);
font-family: 'Source Sans Pro';
font-weight: 300;
font-style: normal;
line-height: 1;
letter-spacing: 0;
font-size: 40px;

@media #{$medium-up} {
font-size: 50px;

}
@media #{$large-up} {
font-size: 55px;

}
}
h3 {
color: var(--clr-158384);
font-family: 'Source Sans Pro';
font-weight: 300;
font-style: normal;
line-height: 1;
font-size: 24px;

@media #{$medium-up} {
font-size: 26px;

}
@media #{$large-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-158384);
font-family: 'Source Sans Pro';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
@media #{$large-up} {
font-size: 25px;

}
}
h5 {
color: #000000;
font-family: 'Source Sans Pro';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-158384);
font-family: 'Source Sans Pro';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Source Sans Pro';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
font-size: 24px;

@media #{$medium-up} {
font-size: 28px;

}
@media #{$large-up} {
font-size: 38px;

}
}
.me-Quote .quote-author {
font-family: 'Source Sans Pro';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: #000000;
font-family: 'Source Sans Pro';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 25;
font-family: 'Source Sans Pro';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
summary {
font-family: 'Source Sans Pro';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
@media #{$large-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Source Sans Pro';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Source Sans Pro';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Source Sans Pro';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Source Sans Pro';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
body {
font-family: 'Source Sans Pro';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-158384);}
/* Primary:3 */
.MES3 {
background-color: var(--clr-158384);
&:hover {background-color: transparent;}
color: #ffffff;
&:hover { color: var(--clr-158384);}
padding: 5px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
&:hover { border-color: var(--clr-158384); }
 }
/* Primary:4 */
.MES4 {
background-color: var(--clr-158384);
color: #ffffff;
 }
.MES4 {
background-color: var(--clr-158384);
color: #ffffff;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: #ffffff;
 }
 }
cite.MEC4{
color: #ffffff;
}
/* Secondary:5 */
.MES5 {
background-color: #000000;
&:hover {background-color: #ffffff;}
color: #ffffff;
&:hover { color: var(--clr-158384);}
padding: 5px 30px;

 }
/* Secondary:6 */
.MES6 {
background-color: #000000;
color: #ffffff;
 }
.MES6 {
background-color: #000000;
color: #ffffff;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: #ffffff;
 }
 }
a.MEC6 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC6{
color: #ffffff;
}
/* Dark:7 */
.MES7 {
background-color: var(--clr-158385);
&:hover {background-color: var(--clr-158384);}
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Dark:8 */
.MES8 {
background-color: var(--clr-158385);
color: var(--clr-158383);
 }
.MES8 {
background-color: var(--clr-158385);
color: var(--clr-158383);
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: var(--clr-158383);
 }
 }
a.MEC8 { color: var(--clr-158383);
&:hover { color: #000000;}
 }
cite.MEC8{
color: var(--clr-158383);
}
/* Light:9 */
.MES9 {
background-color: var(--clr-158383);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-158383);
 }
.MES10 {
background-color: var(--clr-158383);
 }
a.MEC10 { color: #000000;
&:hover { color: #000000;}
 }
/* Shade 1:11 */
.MES11 {
background-color: var(--clr-158386);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-158386);
 }
.MES12 {
background-color: var(--clr-158386);
 }
/* Menu:13 */
.MES13 {
background-color: #ffffff;
color: var(--clr-158385);
font-size: 16px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 100px;};
padding: 0;

border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent var(--clr-158383) transparent;
 }
.MES13 {
background-color: #ffffff;
color: var(--clr-158385);
font-size: 16px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 100px;};
padding: 0;

border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent var(--clr-158383) transparent;
 }
a.MEC13 { color: var(--clr-158385);
&:hover { color: var(--clr-158385);}
 }
cite.MEC13{
color: var(--clr-158385);
font-size: 16px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
}
/* home block panels:14 */
.MES14 {
background-color: #000000;
color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES14 {
background-color: #000000;
color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
h1.MEC14 { font-size: 51px; }
h1.MEC14 { @media #{$medium-up} { font-size: 60px; }; }
h2.MEC14 { font-size: 34px; }
h2.MEC14 { @media #{$medium-up} { font-size: 50px; }; }
h3.MEC14 { font-size: 20.4px; }
h3.MEC14 { @media #{$medium-up} { font-size: 26px; }; }
h4.MEC14 { font-size: 15.3px; }
h4.MEC14 { @media #{$medium-up} { font-size: 22px; }; }
h5.MEC14 { font-size: 14.45px; }
h5.MEC14 { @media #{$medium-up} { font-size: 20px; }; }
h6.MEC14 { font-size: 13.6px; }
h6.MEC14 { @media #{$medium-up} { font-size: 18px; }; }
 }
.MEC14 li {color: #ffffff;}
cite.MEC14{
color: #ffffff;
}
/* Pop out text:15 */
.MES15 {
background-color: #ffffff;
color: var(--clr-158385);
font-size: 17.6px;
@media #{$large-up} {
font-size: 19.2px;
};
 }
.MES15 {
background-color: #ffffff;
color: var(--clr-158385);
font-size: 17.6px;
@media #{$large-up} {
font-size: 19.2px;
};
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: var(--clr-158385);
 }
 }
.MEC15 li {color: var(--clr-158385-flat);}
cite.MEC15{
color: var(--clr-158385);
font-size: 17.6px;
@media #{$large-up} {
font-size: 19.2px;
};
}
/* Hollaw:16 */
.MES16 {
background-color: transparent;
&:hover {background-color: #ffffff;}
color: #ffffff;
&:hover { color: var(--clr-158384);}
font-size: 18px;
@media #{$large-up} {
font-size: 19.8px;
};
border-width: 2px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: transparent; }
 }
/* :17 */
.MES17 {
 }
/* Contents:18 */
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-158385);
font-size: 16px;
 }
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-158385);
font-size: 16px;
 }
a.MEC18 { color: #000000;
&:hover { color: var(--clr-158385);}
 }
cite.MEC18{
color: var(--clr-158385);
font-size: 16px;
}
/* Price Panel:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: #000000;
 }
 }
/* Footer Dark:20 */
.MES20 {
background-color: #000000;
color: var(--clr-158383);
font-size: 12.8px;
 }
.MES20 {
background-color: #000000;
color: var(--clr-158383);
font-size: 12.8px;
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: var(--clr-158384);
 }
 }
a.MEC20 { color: var(--clr-158383);
&:hover { color: var(--clr-158383);}
 }
cite.MEC20{
color: var(--clr-158383);
font-size: 12.8px;
}
/* News grid:21 */
.MES21 {
color: var(--clr-158385);
border-width: 1px;
border-style: solid;
border-color: var(--clr-158383);
 }
.MES21 {
color: var(--clr-158385);
border-width: 1px;
border-style: solid;
border-color: var(--clr-158383);
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: var(--clr-158384);
 }
 }
cite.MEC21{
color: var(--clr-158385);
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: var(--clr-158383);
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Home FAQ:23 */
details.MES23 {
color: #000000;
font-size: 14.4px;
& > article { color: #000000;
font-size: 14.4px;
 }
& > summary{padding: 10px 5px;}

& > article{padding: 15px 15px 15px 55px;}

& > summary{border-width: 0 0 1px 0;
border-style:solid;
border-color: transparent transparent #000000 transparent;
}& > summary { color: #000000;
 }
 }
/* Product Tile:24 */
.MES24 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-158383);}
color: var(--clr-158384);
font-size: 16px;
border-width: 1px;
border-style: solid;
border-color: var(--clr-158383);
 }
.MES24 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-158383);}
color: var(--clr-158384);
font-size: 16px;
border-width: 1px;
border-style: solid;
border-color: var(--clr-158383);
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: var(--clr-158384);
 }
h1.MEC24 { @media #{$large-up} { font-size: 80px; }; }
h2.MEC24 { @media #{$large-up} { font-size: 44px; }; }
h3.MEC24 { @media #{$large-up} { font-size: 24px; }; }
h4.MEC24 { @media #{$large-up} { font-size: 20px; }; }
h5.MEC24 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC24 { @media #{$large-up} { font-size: 14.4px; }; }
 }
cite.MEC24{
color: var(--clr-158384);
font-size: 16px;
}
/* Dark:26 */
.MES26 {
background-color: var(--clr-158385);
color: #ffffff;
padding: 15px 40px;

 }
/* checkout:27 */
.MES27 {
background-color: var(--clr-158384);
&:hover {background-color: transparent;}
color: #ffffff;
&:hover { color: var(--clr-158384);}
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: transparent;
&:hover { border-color: var(--clr-158384); }
 }
/* Top Bar:28 */
.MES28 {
background-color: var(--clr-158384);
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
 }
.MES28 {
background-color: var(--clr-158384);
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: #ffffff;
 }
 }
a.MEC28 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC28{
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* home block panels:29 */
.MES29 {
background-color: #000000;
color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES29 {
background-color: #000000;
color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: #ffffff;
 }
 }
.MEC29 li {color: #ffffff;}
cite.MEC29{
color: #ffffff;
}
/* Category Panel:30 */
.MES30 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-158387);}
color: var(--clr-158384);
border-width: 1px;
border-style: solid;
border-color: var(--clr-158388);
 }
.MES30 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-158387);}
color: var(--clr-158384);
border-width: 1px;
border-style: solid;
border-color: var(--clr-158388);
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: var(--clr-158384);
 }
 }
cite.MEC30{
color: var(--clr-158384);
}
/* Slider text black 2:31 */
.MES31 {
 }
.MES31 {
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: var(--clr-158384);
 }
h1.MEC31 { @media #{$large-up} { font-size: 120px; }; }
h2.MEC31 { @media #{$large-up} { font-size: 66px; }; }
h3.MEC31 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC31 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC31 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC31 { @media #{$large-up} { font-size: 21.6px; }; }
 }
/* Phone Block:32 */
.MES32 {
color: #ffffff;
font-size: 25.12px;
@media #{$medium-up} {
font-size: 35.04px;
};
@media #{$large-up} {
font-size: 48px;
};
 }
.MES32 {
color: #ffffff;
font-size: 25.12px;
@media #{$medium-up} {
font-size: 35.04px;
};
@media #{$large-up} {
font-size: 48px;
};
h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: #ffffff;
 }
h1.MEC32 { @media #{$large-up} { font-size: 120px; }; }
h2.MEC32 { @media #{$large-up} { font-size: 66px; }; }
h3.MEC32 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC32 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC32 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC32 { @media #{$large-up} { font-size: 21.6px; }; }
 }
a.MEC32 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC32{
color: #ffffff;
font-size: 25.12px;
@media #{$medium-up} {
font-size: 35.04px;
};
@media #{$large-up} {
font-size: 48px;
};
}
/* Back menu:33 */
nav.me-Menu.MES33 {
.menu-item.MEC33{background-color: #000000; &:hover {background-color: var(--clr-158384);}
}
& .menu-item.MEC33, & .menu-item.MEC33 > div.MEC33{ & > a.MEC33{color: #ffffff;
font-size: 16px;
text-transform: uppercase;
}
 &:hover > a.MEC33{color: #ffffff;
}
 }
&.horizontal > .menu-item.MEC33 { border:0;
border-color: var(--clr-158383);
border-style: solid;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC33 { border:0;
border-color: var(--clr-158383);
border-style: solid;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC33 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 6;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC33 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 30px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Custom login Panel:34 */
.MES34 {
background-color: var(--clr-158383);
&:hover, &.hover { background-color: var(--clr-158386);}
color: var(--clr-158384);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$medium-up} {
padding: 15px 20px;

}
@media #{$large-up} {
padding: 15px 30px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-158383);
 }
.MES34 {
background-color: var(--clr-158383);
&:hover, &.hover { background-color: var(--clr-158386);}
color: var(--clr-158384);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

@media #{$medium-up} {
padding: 15px 20px;

}
@media #{$large-up} {
padding: 15px 30px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-158383);
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: var(--clr-158384);
 }
 }
cite.MEC34{
color: var(--clr-158384);
}
/* Separator:35 */
.MES35 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-158385);}
& > hr {&:hover { border-color: var(--clr-158385);} }
& > hr {border-top-style: dotted;}
 }
/* Slider panel box:36 */
.MES36 {
background-color: var(--clr-158389);
&:hover, &.hover { background-color: var(--clr-158390);}
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
border-width: 4px;
border-style: solid;
border-color: #ffffff;
 }
.MES36 {
background-color: var(--clr-158389);
&:hover, &.hover { background-color: var(--clr-158390);}
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
border-width: 4px;
border-style: solid;
border-color: #ffffff;
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: #ffffff;
 }
h1.MEC36 { @media #{$large-up} { font-size: 120px; }; }
h2.MEC36 { @media #{$large-up} { font-size: 66px; }; }
h3.MEC36 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC36 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC36 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC36 { @media #{$large-up} { font-size: 21.6px; }; }
 }
a.MEC36 { color: #ffffff;
 }
/* Banner panel:37 */
.MES37 {
color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 550px;};
 }
.MES37 {
color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 550px;};
h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: #ffffff;
 }
 }
.MEC37 li {color: #ffffff;}
cite.MEC37{
color: #ffffff;
}
/* Accordion - How it works:38 */
details.MES38 {
& > summary{background-color: var(--clr-158383);
}
color: var(--clr-158384);
font-size: 14.4px;
& > article { color: var(--clr-158384);
font-size: 14.4px;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
}& > summary { color: var(--clr-158384);
 }
 }
/* tooltips:39 */
.MES39 {
background-color: var(--clr-158384);
&:hover {background-color: transparent;}
color: #ffffff;
&:hover { color: var(--clr-158384);}
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
border-radius: 50%;
padding: 0 2px;

@media #{$large-up} {
padding: 4px 5px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
&:hover { border-color: var(--clr-158384); }
 }
/* Button Grey:40 */
.MES40 {
background-color: #ffffff;
&:hover {background-color: #000000;}
color: #000000;
&:hover { color: #ffffff;}
font-size: 21.6px;
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-158386);
 }
/* Print ready button:41 */
.MES41 {
background-color: var(--clr-158391);
&:hover {background-color: #000000;}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 21.6px;
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-158392);
&:hover { border-color: #ffffff; }
 }
/* Question button:43 */
.MES43 {
background-color: transparent;
&:hover {background-color: var(--clr-158385);}
 }
/* Black:44 */
.MES44 {
background-color: var(--clr-158384);
&:hover {background-color: #000000;}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 18px;
padding: 10px;

 }
/* :45 */
nav.me-Menu.MES45 {
& .menu-item.MEC45, & .menu-item.MEC45 > div.MEC45{ & > a.MEC45{color: #ffffff;
font-size: 12.8px;
text-transform: uppercase;
}
 &:hover > a.MEC45{color: #ffffff;
}
 }
&.horizontal > .menu-item.MEC45 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC45 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC45 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC45 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 5px;}

@media #{$large-up} {
& > .menu-item > a{padding: 5px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* New product button:46 */
.MES46 {
background-color: var(--clr-158383);
&:hover {background-color: #000000;}
color: #000000;
&:hover { color: #ffffff;}
 }
/* Single button box:48 */
.MES48 {
background-color: var(--clr-158390);
&:hover, &.hover { background-color: var(--clr-158393);}
 }
.MES48 {
background-color: var(--clr-158390);
&:hover, &.hover { background-color: var(--clr-158393);}
 }
/* tooltips:49 */
.MES49 {
background-color: var(--clr-158394);
&:hover {background-color: var(--clr-158395);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
border-radius: 50%;
padding: 4px 5px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-158386);
&:hover { border-color: var(--clr-158386); }
 }
/* Main Menu:50 */
nav.me-Menu.MES50 {
& .menu-item.MEC50, & .menu-item.MEC50 > div.MEC50{ & > a.MEC50{color: #ffffff;
font-size: 16px;
text-transform: uppercase;
}
 &:hover > a.MEC50{color: var(--clr-158384);
}
 }
&.horizontal > .menu-item.MEC50 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC50 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC50 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC50 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 30px;}

& .sub-menu .menu-item a{padding: 0 20px 20px 20px;}



&.horizontal .menu-item.MEC50:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid #000000;}}
&.vertical .menu-item.MEC50:hover {& > .pointer-wrap > .pointer{border-right: 10px solid #000000;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC50{background-color: #000000; &:hover {background-color: 3;}
}
& .menu-item.MEC50, & .menu-item.MEC50 > div.MEC50{ & > a.MEC50{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC50{color: 2;
}
 }

}
}
 }
/* Hollow Black:51 */
.MES51 {
background-color: var(--clr-158386);
&:hover {background-color: var(--clr-158385);}
color: var(--clr-158385);
&:hover { color: #ffffff;}
font-size: 18px;
@media #{$large-up} {
font-size: 19.8px;
};
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-158385);
&:hover { border-color: transparent; }
 }
/* Home Block Button:52 */
.MES52 {
background-color: var(--clr-158384);
&:hover {background-color: transparent;}
color: #ffffff;
&:hover { color: var(--clr-158385);}
font-size: 18px;
@media #{$large-up} {
font-size: 18.9px;
};
padding: 5px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
&:hover { border-color: var(--clr-158385); }
 }
/* Accrodion:53 */
details.MES53 {
color: #000000;
font-size: 14.4px;
& > article { color: #000000;
font-size: 14.4px;
 }
& > summary{padding: 10px 5px;}

& > article{padding: 15px 15px 15px 40px;}

& > summary{border-width: 0 0 1px 0;
border-style:solid;
border-color: transparent transparent #000000 transparent;
}& > summary { color: #000000;
 }
 }
/* Popup panel:54 */
.MES54 {
background-color: var(--clr-158383);
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
 }
.MES54 {
background-color: var(--clr-158383);
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
 }
/* Highlight:55 */
.MES55 {
background-color: var(--clr-158384);
color: #ffffff;
 }
/* Highlight:56 */
.MES56 {
background-color: var(--clr-158384);
color: #ffffff;
 }
.MES56 {
background-color: var(--clr-158384);
color: #ffffff;
h1.MEC56, h2.MEC56, h3.MEC56, h4.MEC56, h5.MEC56, h6.MEC56 { color: #ffffff;
 }
 }
cite.MEC56{
color: #ffffff;
}
/* Spacer 40px:57 */
.MES57 {
padding: 10px;

@media #{$medium-up} {
padding: 20px 10px;

}
 }
/* option chooser panel normal:58 */
.MES58 {
background-color: transparent;
color: var(--clr-158385);
font-size: 16px;
@media #{$large-up} {
font-size: 19.2px;
};
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-158385);
 }
.MES58 {
background-color: transparent;
color: var(--clr-158385);
font-size: 16px;
@media #{$large-up} {
font-size: 19.2px;
};
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-158385);
h1.MEC58, h2.MEC58, h3.MEC58, h4.MEC58, h5.MEC58, h6.MEC58 { color: var(--clr-158385);
 }
h1.MEC58 { font-size: 39px; }
h1.MEC58 { @media #{$medium-up} { font-size: 48px; }; }
h1.MEC58 { @media #{$large-up} { font-size: 70px; }; }
h2.MEC58 { font-size: 26px; }
h2.MEC58 { @media #{$medium-up} { font-size: 40px; }; }
h2.MEC58 { @media #{$large-up} { font-size: 38.5px; }; }
h3.MEC58 { font-size: 15.6px; }
h3.MEC58 { @media #{$medium-up} { font-size: 20.8px; }; }
h3.MEC58 { @media #{$large-up} { font-size: 21px; }; }
h4.MEC58 { font-size: 11.7px; }
h4.MEC58 { @media #{$medium-up} { font-size: 17.6px; }; }
h4.MEC58 { @media #{$large-up} { font-size: 17.5px; }; }
h5.MEC58 { font-size: 11.05px; }
h5.MEC58 { @media #{$medium-up} { font-size: 16px; }; }
h5.MEC58 { @media #{$large-up} { font-size: 14px; }; }
h6.MEC58 { font-size: 10.4px; }
h6.MEC58 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC58 { @media #{$large-up} { font-size: 12.6px; }; }
 }
a.MEC58 { color: var(--clr-158385);
&:hover { color: var(--clr-158385);}
 }
cite.MEC58{
color: var(--clr-158385);
font-size: 16px;
@media #{$large-up} {
font-size: 19.2px;
};
}
/* option chooser panel normal:59 */
.MES59 {
background-color: var(--clr-158384);
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 19.2px;
};
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES59 {
background-color: var(--clr-158384);
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 19.2px;
};
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: transparent;
h1.MEC59, h2.MEC59, h3.MEC59, h4.MEC59, h5.MEC59, h6.MEC59 { color: #ffffff;
 }
h1.MEC59 { font-size: 39px; }
h1.MEC59 { @media #{$medium-up} { font-size: 48px; }; }
h1.MEC59 { @media #{$large-up} { font-size: 70px; }; }
h2.MEC59 { font-size: 26px; }
h2.MEC59 { @media #{$medium-up} { font-size: 40px; }; }
h2.MEC59 { @media #{$large-up} { font-size: 38.5px; }; }
h3.MEC59 { font-size: 15.6px; }
h3.MEC59 { @media #{$medium-up} { font-size: 20.8px; }; }
h3.MEC59 { @media #{$large-up} { font-size: 21px; }; }
h4.MEC59 { font-size: 11.7px; }
h4.MEC59 { @media #{$medium-up} { font-size: 17.6px; }; }
h4.MEC59 { @media #{$large-up} { font-size: 17.5px; }; }
h5.MEC59 { font-size: 11.05px; }
h5.MEC59 { @media #{$medium-up} { font-size: 16px; }; }
h5.MEC59 { @media #{$large-up} { font-size: 14px; }; }
h6.MEC59 { font-size: 10.4px; }
h6.MEC59 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC59 { @media #{$large-up} { font-size: 12.6px; }; }
 }
a.MEC59 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC59{
color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 19.2px;
};
}
/* Spacer 50px:60 */
.MES60 {
padding: 20px 15px;

@media #{$large-up} {
padding: 25px 15px;

}
 }
/* quote panel:61 */
.MES61 {
 }
.MES61 {
h1.MEC61, h2.MEC61, h3.MEC61, h4.MEC61, h5.MEC61, h6.MEC61 { color: #000000;
 }
h1.MEC61 { @media #{$large-up} { font-size: 110px; }; }
h2.MEC61 { @media #{$large-up} { font-size: 60.5px; }; }
h3.MEC61 { @media #{$large-up} { font-size: 33px; }; }
h4.MEC61 { @media #{$large-up} { font-size: 27.5px; }; }
h5.MEC61 { @media #{$large-up} { font-size: 22px; }; }
h6.MEC61 { @media #{$large-up} { font-size: 19.8px; }; }
 }
/* Add to Cart:62 */
.MES62 {
background-color: var(--clr-158384);
&:hover {background-color: transparent;}
color: #ffffff;
&:hover { color: var(--clr-158384);}
font-size: 18px;
@media #{$large-up} {
font-size: 19.8px;
};
border-width: 1px;
border-style: solid;
border-color: transparent;
&:hover { border-color: var(--clr-158384); }
 }
/* Checkout Panel:63 */
.MES63 {
background-color: var(--clr-158384);
&:hover, &.hover { background-color: transparent;}
min-height:40px;
@media #{$medium-up} {
min-height: 40px;};
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-158384);
 }
.MES63 {
background-color: var(--clr-158384);
&:hover, &.hover { background-color: transparent;}
min-height:40px;
@media #{$medium-up} {
min-height: 40px;};
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-158384);
 }
/* product panel:64 */
.MES64 {
 }
.MES64 {
h1.MEC64, h2.MEC64, h3.MEC64, h4.MEC64, h5.MEC64, h6.MEC64 { color: var(--clr-158384);
 }
h1.MEC64 { font-size: 72px; }
h1.MEC64 { @media #{$medium-up} { font-size: 78px; }; }
h1.MEC64 { @media #{$large-up} { font-size: 150px; }; }
h2.MEC64 { font-size: 48px; }
h2.MEC64 { @media #{$medium-up} { font-size: 65px; }; }
h2.MEC64 { @media #{$large-up} { font-size: 82.5px; }; }
h3.MEC64 { font-size: 28.8px; }
h3.MEC64 { @media #{$medium-up} { font-size: 33.8px; }; }
h3.MEC64 { @media #{$large-up} { font-size: 45px; }; }
h4.MEC64 { font-size: 21.6px; }
h4.MEC64 { @media #{$medium-up} { font-size: 28.6px; }; }
h4.MEC64 { @media #{$large-up} { font-size: 37.5px; }; }
h5.MEC64 { font-size: 20.4px; }
h5.MEC64 { @media #{$medium-up} { font-size: 26px; }; }
h5.MEC64 { @media #{$large-up} { font-size: 30px; }; }
h6.MEC64 { font-size: 19.2px; }
h6.MEC64 { @media #{$medium-up} { font-size: 23.4px; }; }
h6.MEC64 { @media #{$large-up} { font-size: 27px; }; }
 }
/* Banner Quote:65 */
.MES65 {
background-color: transparent;
&:hover {background-color: var(--clr-158384);}
color: var(--clr-158384);
&:hover { color: #ffffff;}
font-size: 18px;
@media #{$large-up} {
font-size: 19.8px;
};
padding: 10px 40px;

@media #{$large-up} {
padding: 10px 42px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-158384);
&:hover { border-color: transparent; }
 }
/* Slider Panel:66 */
.MES66 {
 }
.MES66 {
h1.MEC66 { font-size: 36px; }
h1.MEC66 { @media #{$medium-up} { font-size: 60px; }; }
h1.MEC66 { @media #{$large-up} { font-size: 101px; }; }
h2.MEC66 { font-size: 24px; }
h2.MEC66 { @media #{$medium-up} { font-size: 50px; }; }
h2.MEC66 { @media #{$large-up} { font-size: 55.55px; }; }
h3.MEC66 { font-size: 14.4px; }
h3.MEC66 { @media #{$medium-up} { font-size: 26px; }; }
h3.MEC66 { @media #{$large-up} { font-size: 30.3px; }; }
h4.MEC66 { font-size: 10.8px; }
h4.MEC66 { @media #{$medium-up} { font-size: 22px; }; }
h4.MEC66 { @media #{$large-up} { font-size: 25.25px; }; }
h5.MEC66 { font-size: 10.2px; }
h5.MEC66 { @media #{$medium-up} { font-size: 20px; }; }
h5.MEC66 { @media #{$large-up} { font-size: 20.2px; }; }
h6.MEC66 { font-size: 9.6px; }
h6.MEC66 { @media #{$medium-up} { font-size: 18px; }; }
h6.MEC66 { @media #{$large-up} { font-size: 18.18px; }; }
 }
/* Home Black Strip:67 */
.MES67 {
background-color: #000000;
color: #ffffff;
padding: 20px 15px;

@media #{$medium-up} {
padding: 30px 20px;

}
@media #{$large-up} {
padding: 40px 20px;

}
 }
.MES67 {
background-color: #000000;
color: #ffffff;
padding: 20px 15px;

@media #{$medium-up} {
padding: 30px 20px;

}
@media #{$large-up} {
padding: 40px 20px;

}
h1.MEC67, h2.MEC67, h3.MEC67, h4.MEC67, h5.MEC67, h6.MEC67 { color: #ffffff;
 }
 }
a.MEC67 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC67{
color: #ffffff;
}
/* Shade Button:68 */
.MES68 {
background-color: var(--clr-158386);
&:hover {background-color: var(--clr-158386);}
color: #000000;
&:hover { color: #000000;}
font-size: 18px;
@media #{$large-up} {
font-size: 19.8px;
};
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: #000000;
&:hover { border-color: #000000; }
 }
/* Cart Icon:69 */
.MES69 {
 & .cart-panel{  }
 color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 20.8px;
}}/* Continue shopping:70 */
.MES70 {
background-color: transparent;
&:hover {background-color: var(--clr-158384);}
color: var(--clr-158384);
&:hover { color: #ffffff;}
font-size: 18px;
padding: 10px 40px;

@media #{$large-up} {
padding: 10px 42px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-158384);
&:hover { border-color: transparent; }
 }
/* Cart Action Button:71 */
.MES71 {
background-color: transparent;
&:hover {background-color: transparent;}
color: var(--clr-158391);
&:hover { color: var(--clr-158391);}
font-size: 12.6px;
@media #{$medium-up} {
font-size: 18px;
};
@media #{$large-up} {
font-size: 16.2px;
};
padding: 1px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-158391) transparent;
 }
/* Upload Artwork:72 */
.MES72 {
background-color: transparent;
&:hover {background-color: transparent;}
color: var(--clr-158384);
&:hover { color: var(--clr-158384);}
font-size: 12.6px;
@media #{$medium-up} {
font-size: 18px;
};
@media #{$large-up} {
font-size: 16.2px;
};
padding: 1px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-158384) transparent;
 }
/* Product Selector Panel:73 */
.MES73 {
 }
.MES73 {
h1.MEC73, h2.MEC73, h3.MEC73, h4.MEC73, h5.MEC73, h6.MEC73 { color: var(--clr-158396);
 }
 }
/* Banner Quote:74 */
.MES74 {
background-color: transparent;
&:hover {background-color: var(--clr-158384);}
color: var(--clr-158384);
&:hover { color: #ffffff;}
font-size: 14.4px;
@media #{$medium-up} {
font-size: 18px;
};
@media #{$large-up} {
font-size: 19.8px;
};
padding: 5px 20px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-158384);
&:hover { border-color: transparent; }
 }
/* Save Quote Button:75 */
.MES75 {
background-color: var(--clr-158386);
&:hover {background-color: var(--clr-158386);}
color: #000000;
&:hover { color: #000000;}
font-size: 18px;
@media #{$large-up} {
font-size: 19.8px;
};
padding: 5px 20px;

border-width: 1px;
border-style: solid;
border-color: #000000;
&:hover { border-color: #000000; }
 }
/* TabChooser Next Button:76 */
.MES76 {
background-color: var(--clr-158384);
&:hover {background-color: transparent;}
color: #ffffff;
&:hover { color: var(--clr-158384);}
font-size: 18px;
@media #{$large-up} {
font-size: 19.8px;
};
padding: 5px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
&:hover { border-color: var(--clr-158384); }
 }
/* Active Tab:77 */
.MES77 {
color: var(--clr-158384);
border-width: 1px;
@media #{$medium-up} { 
border-width: 1px 1px 0 1px;
};
border-style: solid;
border-color: var(--clr-158385);
 }
/* Inactive Tab:78 */
.MES78 {
color: var(--clr-158396);
&:hover { color: var(--clr-158384);}
border-width: 0 0 1px 0;
border-style: solid;
border-color: var(--clr-158385);
 }
/* Responsive Menu:79 */
nav.responsive-menu {
& .menu-item.MEC79, & .menu-item.MEC79 > div.menu-item-wrap{ & > a.MEC79, & > i{text-align: left;
}
  }
& .menu-item.MEC79 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 0 10px;}

& .sub-menu{ .sub-menu {}}

 }
/* Page Accordion:80 */
details.MES80 {
 }
